import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INPUTS_IVITE, ProfileSettingsState } from '../types'

const initialState: ProfileSettingsState = {
  [INPUTS_IVITE.FIRST_NAME]: '',
  [INPUTS_IVITE.LAST_NAME]: '',
  [INPUTS_IVITE.PHONE]: '',
  [INPUTS_IVITE.EMAIL]: '',
  id: '',
}

export const profileSettingsSlice = createSlice({
  name: 'ProfieEdit',
  initialState,
  reducers: {
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state[name] = value
      return state
    },
    setFetchSettingsData: (state, action: PayloadAction<any>) => {
      state = action.payload
      return state
    },
  },
})

// Actions
export const { setValueInput, setFetchSettingsData } = profileSettingsSlice.actions

export default profileSettingsSlice.reducer
