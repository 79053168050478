import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { MarketState } from '../types'

const initialState: MarketState = {
  fetching: false,
  fetchData: {},
  filterOpen: false,
  checkedOptions: {
    status: [],
    category: [],
    movement: [],
    period: [],
    materials: [],
    market: [],
  },
  selectedOptions: {
    limit: 0,
    offset: 0,
    orderBy: 'dateEnd',
    orderSort: 'desc',
    status: '',
    ticker: 'ETH',
    name: 'Ethereum',
    decimals: 18,
    priceRangeFrom: '',
    priceRangeTo: '',
    museumId: '',
  },
  filterList: {
    market: [],
    category: [],
    materials: [],
    status: [],
    movement: [],
    period: [],
  },
  countriy: [
    {
      code: '',
      name: '',
    },
  ],
  fetchCountriy: true,
  fetchFilter: true,
}

// /list/filter

export const fetchFilterListData = createAsyncThunk<{ data: any }>('Market/filterList', async () => {
  const response = await axios(`${process.env.REACT_APP_API}/list/filter`)
  if (response?.status === 200) {
    const { data } = response
    return { data }
  }
  return {
    data: {
      category: [],
      materials: [],
      status: [],
      movement: [],
      period: [],
    },
  }
})

export const fetchCountryListData = createAsyncThunk<{ data: any }>('Market/countryList', async () => {
  const response = await axios(`${process.env.REACT_APP_API}/list/countries`)
  if (response?.status === 200) {
    const data = response.data.map((item) => ({ ...item, value: item.code }))
    return { data }
  }
  return {
    data: [
      {
        code: '',
        name: '',
      },
    ],
  }
})

export const marketSlice = createSlice({
  name: 'Market',
  initialState,
  reducers: {
    fetchDataAction: (state, action: PayloadAction<boolean>) => {
      state = { ...state, fetchData: action.payload }
      return state
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state = { ...state, fetching: action.payload }
      return state
    },
    setFilerOpen: (state, action: PayloadAction<boolean>) => {
      state = { ...state, filterOpen: action.payload }
      return state
    },
    setCheckboxFiltersList: (state, action: PayloadAction<any>) => {
      const name = state.checkedOptions[action.payload.name]
      state.checkedOptions = {
        ...state.checkedOptions,
        [action.payload.name]: name.length ? [...name, action.payload.value] : [action.payload.value],
      }
    },
    setFiltersList: (state, action: PayloadAction<any>) => {
      state.selectedOptions = { ...state.selectedOptions, [action.payload.name]: action.payload.value }
    },
    removeCheckboxFiltersList: (state, action: PayloadAction<any>) => {
      state.checkedOptions[action.payload.name] = state.checkedOptions[action.payload.name].filter(
        (el) => el !== action.payload.value,
      )
    },
    priceFiltersClear: (state) => {
      state.selectedOptions = {
        limit: 0,
        offset: 0,
        orderBy: '',
        orderSort: '',
        status: '',
        ticker: 'ETH',
        name: 'Ethereum',
        decimals: 18,
        priceRangeFrom: '',
        priceRangeTo: '',
        museumId: '',
      }
    },
    clearList: (state) => {
      state = {
        ...state,
        selectedOptions: initialState.selectedOptions,
        checkedOptions: initialState.checkedOptions,
        filterOpen: true,
      }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilterListData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.filterList = data
      state.fetchFilter = state.fetchFilter && false
    })
    builder.addCase(fetchCountryListData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.countriy = data
      state.fetchCountriy = state.fetchCountriy && false
    })
  },
})

// Actions
export const {
  clearList,
  setFilerOpen,
  setCheckboxFiltersList,
  setFiltersList,
  removeCheckboxFiltersList,
  priceFiltersClear,
  fetchDataAction,
  setFetching,
} = marketSlice.actions

export default marketSlice.reducer
