const ACCESS_TOKEN = 'asset_token_art'
const TYPE_ACCOUNT = 'type_account'
const INVATION_TOKEN = 'invitation_token'
const EDIT_PROFILE_TOKEN = 'edit_profile_token'
const CREATE_SERIES_TOKEN = 'edit_series_token'
const CREATE_COLLECTIONER_TOKEN = 'edit_collectioner_token'

export enum TYPES_ACCOUNT {
  MUSEUM = 'museum',
  PROFILE = 'collector',
  NONE = 'none',
}

// eslint-disable-next-line consistent-return
export const getToken = () => {
  try {
    const info = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
    return info
  } catch (e) {
    removeToken()
  }
  return undefined
}
export const setToken = (token: string, account?: string) => {
  localStorage.setItem(ACCESS_TOKEN, JSON.stringify({ token, account }))
}

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN)
}

export const requestAuthRevoke = () => {
  removeToken()
}

export const isAuthenticated = (account) => {
  const data = getToken()
  if (data) {
    const { account: acc, token, verification } = data
    return acc === account && token && verification
  }
  return false
}

export const setTypeAccount = (type: TYPES_ACCOUNT) => {
  localStorage.setItem(TYPE_ACCOUNT, JSON.stringify(type))
}

export const removeTypeAccount = () => {
  localStorage.removeItem(TYPE_ACCOUNT)
}

export const getTypeAccount = () => {
  try {
    const type = JSON.parse(localStorage.getItem(TYPE_ACCOUNT))
    return type
  } catch (e) {
    removeTypeAccount()
  }
  return undefined
}

export const setInvitation = (value) => {
  localStorage.setItem(INVATION_TOKEN, JSON.stringify(value))
}

export const removeInvitation = () => {
  localStorage.removeItem(INVATION_TOKEN)
}

export const getInvitation = () => {
  try {
    const type = JSON.parse(localStorage.getItem(INVATION_TOKEN))
    return type
  } catch (e) {
    removeInvitation()
  }
  return undefined
}

export const setEditProfile = (value) => {
  localStorage.setItem(EDIT_PROFILE_TOKEN, JSON.stringify(value))
}

export const getEditProfile = () => {
  try {
    const type = JSON.parse(localStorage.getItem(EDIT_PROFILE_TOKEN))
    return type
  } catch (e) {
    removeTypeAccount()
  }
  return undefined
}

export const removeEditProfile = () => {
  localStorage.removeItem(EDIT_PROFILE_TOKEN)
}

export const setCreateRealese = (value) => {
  localStorage.setItem(CREATE_SERIES_TOKEN, JSON.stringify(value))
}

export const getCreateRealese = () => {
  try {
    const type = JSON.parse(localStorage.getItem(CREATE_SERIES_TOKEN))
    return type
  } catch (e) {
    removeTypeAccount()
  }
  return undefined
}

export const removeCreateRealese = () => {
  localStorage.removeItem(CREATE_SERIES_TOKEN)
}

export const setCreateCollectioner = (value) => {
  localStorage.setItem(CREATE_COLLECTIONER_TOKEN, JSON.stringify(value))
}

export const getCreateCollectioner = () => {
  try {
    const type = JSON.parse(localStorage.getItem(CREATE_COLLECTIONER_TOKEN))
    return type
  } catch (e) {
    removeTypeAccount()
  }
  return undefined
}

export const removeCreateCollectionern = () => {
  localStorage.removeItem(CREATE_COLLECTIONER_TOKEN)
}

export const isUnauthenticated = (account) => !isAuthenticated(account)
