import { COLOR_BLACK_TEXT } from 'constants/styles/colors'
import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
  }
  body {
    width: 100vw;
    background-color: #fff;
    overflow-x: hidden;
    color: ${COLOR_BLACK_TEXT};
    img {
      height: auto;
      max-width: 100%;
    }
  }    
  #root {
    overflow: hidden;
  }
  html {
    overflow-x: hidden;
    /* font-size: calc(1vw / 14.4); */
    font-size: 0.7142857142857143vw
  } 
`

export default GlobalStyle
