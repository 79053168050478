import React, { useEffect, useState } from 'react'
import { State } from 'state/types'
import styled from 'styled-components'
import { setNotice } from 'state/notice'
import { useDispatch, useSelector } from 'react-redux'
import NoticeVariant from './NoticeVariant'

export default function NoticeGlobal() {
  const noticeState = useSelector((state: State) => state.notice)
  const { open, variant, title, actions } = noticeState
  const [notices, setNotices] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    const id = Math.random()
    if (open) setNotices((n) => [...n, { open, variant, title, actions, id }])
  }, [open, variant, title, actions])

  useEffect(() => {
    if (notices.length === 0) dispatch(setNotice({ open: false, title: '', variant: null }))
  }, [notices, dispatch])

  const handleClose = (e: any) => {
    notices.forEach((el, i) => {
      if (el.id.toString() === e.target.value) {
        notices.splice(i, 1)
        setNotices([...notices])
      }
    })
  }

  return (
    <NoticeWrapper open={open}>
      {notices.map((note) => (
        <NoticeVariant handleClose={handleClose} key={note.id} {...note} />
      ))}
    </NoticeWrapper>
  )
}
const NoticeWrapper = styled.div<{ open: boolean }>`
  position: relative;
  padding: ${({ open }) => (open ? '16px' : 0)};
  height: ${({ open }) => (open ? 'auto' : 0)};
  max-height: ${({ open }) => (open ? '1000px' : 0)};
  transition: all 0.3s ease-out;
`
