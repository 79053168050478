import { COLOR_BLACK_TEXT } from 'constants/styles/colors'
import { createGlobalStyle } from 'styled-components'

const ResetCSS = createGlobalStyle`
*{
  font-family: 'Poppins', sans-serif;
}
  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  /* prettier-ignore */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size: 16px;
    min-height: 100vh;
  }
  ol,
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  } 
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 0;
  }

  div::selection, p::selection, a::selection, button::selection, input::selection {
    color: black;
    background-color: rgba(0, 0, 0, 0.2);
}
div::-moz-selection, p::-moz-selection, a::-moz-selection, button::-moz-selection, input::-moz-selection {
    color: black;
    background-color: rgba(0, 0, 0, 0.2);
}


  // @todo узнать скроллбары
  /* ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0; 
  } */
  /* Slider */ 
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
  }
  input:-internal-autofill-selected,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input:focus {
    transition: .3s;
    border: 1px solid ${COLOR_BLACK_TEXT};
}
  

  button {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }

  input[type=file]::-webkit-file-upload-button { 
    cursor: pointer; 
}
`

export default ResetCSS
