import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'
import pancakeV2Router02Abi from 'config/abi/pancakeV2Router02.json'
import { BlockChainNetwork } from './constants/types'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const CAKE_PER_BLOCK = new BigNumber(40)
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK.times(BLOCKS_PER_YEAR)
export const BASE_URL = 'https://pancakeswap.finance'
export const BASE_EXCHANGE_URL = 'https://exchange.pancakeswap.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const BASE_BSC_SCAN_URL = 'https://bscscan.com'
export const BASE_AVAX_SCAN_URL = 'https://cchain.explorer.avax.network'
export const ETH_RPC = 'https://mainnet.infura.io/v3/'
export const AVAX_RPC = 'https://api.avax.network/ext/bc/C/rpc'
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000
export const DEFAULT_GAS_PRICE = 5
export const TESTNET_CHAIN_ID = '5'
export const MAINNET_CHAIN_ID = '1'

export const TESTNET_ETH_CHAIN_ID = '5'
export const MAINNET_ETH_CHAIN_ID = '1'
export const MAINNET_AVAX_CHAIN_ID = '43114'
export const TESTNET_AVAX_CHAIN_ID = '43113'

export const connectorLocalStorageKey = 'connectorId'
export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
  Coin98 = 'coin98',
  WalletLink = 'walletLink',
}

export enum NETWORK_TYPE {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
}
export const NETWORK_CHAIN: BlockChainNetwork[] = [
  {
    name: 'Ethereum',
    shortName: 'ETH',
    chainId: '1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Eth',
      symbol: 'Eth',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    blockExplorerUrls: ['https://etherscan.io/'],
    icon: '/images/bnb.png',
    dex: {
      dexAddress: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
      dexABI: pancakeV2Router02Abi as unknown as string,
    },
  },
  {
    name: 'Ethereum',
    shortName: 'ETH',
    chainId: '5',
    chainName: 'Goerli test network',
    nativeCurrency: {
      name: 'Eth',
      symbol: 'GoerliETH',
      decimals: 18,
    },
    rpcUrls: ['https://goerli.infura.io/v3/86dcf7d9ba97497b977817021eec61cb'],
    blockExplorerUrls: ['https://goerli.infura.io/v3/'],
    icon: '/images/bnb.png',
    dex: {
      dexAddress: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
      dexABI: pancakeV2Router02Abi as unknown as string,
    },
  },
]

export const COOKIES_ITEM = 'cookies'
export const OWNER_WALLET = '0x9c6221ea7541bf4e74b112bcd984e50d35bdbac9'
