import React, { createContext, ReactNode, useCallback, useState } from 'react'
import { kebabCase } from 'lodash'
import { toastTypes, Toast } from 'components/Toast/types'
import { ToastContextApi } from './types'

export const ToastsContext = createContext<ToastContextApi>(undefined)

export const ToastsProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastContextApi['toasts']>([])

  const toast = useCallback(
    ({ title, description, type, actions }: Omit<Toast, 'id'>) => {
      setToasts((prevToasts) => {
        const id = kebabCase(title)

        // Remove any existing toasts with the same id
        const currentToasts = prevToasts.filter((prevToast) => prevToast.id !== id)

        return [
          {
            id,
            title,
            description,
            type,
            actions,
          },
          ...currentToasts,
        ]
      })
    },
    [setToasts],
  )

  const toastWarning = (title: string, description?: ReactNode, actions?: ReactNode) => {
    return toast({ title, description, type: toastTypes.WARNING, actions })
  }
  const toastInfo = (title: string, description?: ReactNode, actions?: ReactNode) => {
    return toast({ title, description, type: toastTypes.INFO, actions })
  }
  const toastSuccess = (title: string, description?: ReactNode, actions?: ReactNode) => {
    return toast({ title, description, type: toastTypes.SUCCESS, actions })
  }
  const toastError = (title: string, description?: ReactNode, actions?: ReactNode) => {
    return toast({ title, description, type: toastTypes.ERROR, actions })
  }
  const clear = () => setToasts([])
  const remove = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((prevToast) => prevToast.id !== id))
  }

  return (
    <ToastsContext.Provider value={{ toasts, clear, remove, toastWarning, toastInfo, toastSuccess, toastError }}>
      {children}
    </ToastsContext.Provider>
  )
}
