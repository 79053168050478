/* eslint-disable */
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import Web3 from 'web3'
import getNodeUrl from './getRpcUrl'
import { getBlockchainNetwork, getNetwork } from './network'

enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  WalletLink = 'walletLink',
}

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const blockchainNetwork = getBlockchainNetwork(getNetwork())
const chainId = parseInt(blockchainNetwork.chainId, 10)

const injected = new InjectedConnector({ supportedChainIds: [chainId] })

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge: 'https://pancakeswap.bridge.walletconnect.org/',
  qrcode: true,
  //@ts-ignore
  pollingInterval: POLLING_INTERVAL,
})

const walletLinkConnector = new WalletLinkConnector({
  url: rpcUrl,
  appName: 'Artwhale',
  appLogoUrl: '',
  darkMode: false,
  supportedChainIds: [chainId],
})

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletLinkConnector,
}

export const getLibrary = (provider): Web3 => {
  return provider
}
