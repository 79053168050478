import React from 'react'

export default function CloseIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8333 1.34175L10.6583 0.166748L6.00001 4.82508L1.34167 0.166748L0.166672 1.34175L4.82501 6.00008L0.166672 10.6584L1.34167 11.8334L6.00001 7.17508L10.6583 11.8334L11.8333 10.6584L7.17501 6.00008L11.8333 1.34175Z"
        fill="#BCBCBC"
      />
    </svg>
  )
}
