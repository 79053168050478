import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Landing } from '../types'

const initialState: Landing = {
  trends: [],
  isFetchingTrends: true,
}

export const fetchLandingTrendsData = createAsyncThunk('Landing/trend', async () => {
  const response = await axios(`${process.env.REACT_APP_API}/artwork/trend`)
  if (response?.status === 200) {
    return { data: response.data }
  }
  return {
    data: {},
  }
})

export const headerSlice = createSlice({
  name: 'Header',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLandingTrendsData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.trends = data
      state.isFetchingTrends = state.isFetchingTrends && false
    })
  },
})

// Actions
// export const {} = headerSlice.actions

export default headerSlice.reducer
