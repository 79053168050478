import React from 'react'

export default function WarningIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.1006C22 17.6206 17.52 22.1006 12 22.1006C6.48 22.1006 2 17.6206 2 12.1006C2 6.58059 6.48 2.10059 12 2.10059C17.52 2.10059 22 6.58059 22 12.1006ZM11 13.1006L11 7.10059L13 7.10059L13 13.1006L11 13.1006ZM12 4.10059C16.41 4.10059 20 7.6906 20 12.1006C20 16.5106 16.41 20.1006 12 20.1006C7.59001 20.1006 4.00002 16.5106 4.00002 12.1006C4.00002 7.69059 7.59002 4.10059 12 4.10059ZM11 17.1006L11 15.1006L13 15.1006L13 17.1006L11 17.1006Z"
        fill="white"
      />
    </svg>
  )
}
