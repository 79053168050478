import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
// import { throttle } from 'lodash'
import { loadState, saveState } from '../utils/localStorage'
import HeaderReducer from './header'
import MuseumReducer from './museum'
import MarketReducer from './market'
import ReleaseReducer from './release'
import ProfileReducer from './Profile'
import CollectorReducer from './collector'
import ProfileCreateReducer from './profileCreate'
import NoticeReducer from './notice'
import InvitateReducer from './invite'
import ProfileEditMuseumReducer from './profileEditMuseum'
import ReleaseCreateReducer from './releaseCreate'
import ProfileSettingsReducer from './ProfileSettings'
import ProfileEditCollectorReducer from './profileEditCollector'
import AuthReducer from './auth'
import ArtworkReducer from './artwork'
import MuseumFriendsReducer from './museumFriends'
import Landingreducer from './landing'

const store = configureStore({
  // devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    header: HeaderReducer,
    museum: MuseumReducer,
    market: MarketReducer,
    release: ReleaseReducer,
    profile: ProfileReducer,
    collector: CollectorReducer,
    profileCreate: ProfileCreateReducer,
    notice: NoticeReducer,
    invite: InvitateReducer,
    profileEditCollector: ProfileEditCollectorReducer,
    releaseCreate: ReleaseCreateReducer,
    profileSettings: ProfileSettingsReducer,
    profileEditMuseum: ProfileEditMuseumReducer,
    auth: AuthReducer,
    artwork: ArtworkReducer,
    museumFriends: MuseumFriendsReducer,
    landing: Landingreducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  preloadedState: loadState(),
})

// чтобы добавлять все сторы сразу, использовать код ниже
// store.subscribe(
// 	throttle( () => saveState(store.getState()), 1000)
// );
// store.subscribe(() => {
//   saveState({
//     releaseCreate: store.getState().releaseCreate,
//   })
// })

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
