import Loader from 'components/Loader'
import COLOR_WHITE, {
  COLOR_BLACK_TEXT,
  COLOR_GRAY_2,
  COLOR_GRAY_3,
  COLOR_GRAY_TEXT,
  COLOR_GRAY_TEXT_2,
} from 'constants/styles/colors'
import React from 'react'
import styled from 'styled-components'
import {
  space,
  layout,
  variant as variantStyled,
  typography,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  flexbox,
  FlexboxProps,
} from 'styled-system'

interface ButtonProps {
  spin?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  scale?: Scale
}

export type Scale = typeof scales[keyof typeof scales]
interface ButtonCompProps {
  children: React.ReactNode
  onClick?: () => void | Promise<void>
  variant?: string
  buttonProps?: ButtonProps & LayoutProps & SpaceProps & TypographyProps & FlexboxProps
  padding?: string
  disabled?: boolean
}

export const scales = {
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
} as const

export const scaleVariants = {
  [scales.SM]: {
    height: '41px',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '10px 40px',
  },
  [scales.XS]: {
    height: '35px',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '7px 20px',
  },
}

export default function Button({ children, onClick, variant, padding, disabled, buttonProps }: ButtonCompProps) {
  return (
    <ButtonBlock padding={padding} variant={variant} onClick={onClick} disabled={disabled} {...buttonProps}>
      {buttonProps && buttonProps.spin ? <Loader size="medium" /> : children}
    </ButtonBlock>
  )
}

const ButtonBlock = styled.button<{ variant?: string; padding?: string; spin?: boolean }>`
  width: 100%;
  height: 53px;
  padding: ${({ padding }) => padding || '14px 20px'};
  background: ${({ variant }) =>
    variant === 'white' ? COLOR_GRAY_3 : variant === 'transparent' ? 'transparent' : COLOR_BLACK_TEXT};
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${({ variant }) =>
    variant === 'white' ? COLOR_BLACK_TEXT : variant === 'transparent' ? COLOR_BLACK_TEXT : COLOR_WHITE};
  border: 1px solid
    ${({ variant }) =>
      variant === 'white' ? COLOR_GRAY_3 : variant === 'transparent' ? COLOR_BLACK_TEXT : COLOR_BLACK_TEXT};
  box-sizing: border-box;
  transition: all 0.3s;
  white-space: nowrap;
  flex: none;
  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: ${COLOR_GRAY_3};
    border: 1px solid ${COLOR_GRAY_3};
    color: ${COLOR_GRAY_TEXT_2};
  }
  &:hover {
    background: ${({ variant }) => (variant === 'white' ? COLOR_GRAY_3 : COLOR_GRAY_2)};
    border: 1px solid ${({ variant }) => (variant === 'white' ? COLOR_GRAY_TEXT : COLOR_GRAY_2)};
    color: ${({ variant }) =>
      variant === 'transparent'
        ? COLOR_WHITE
        : variant === 'white'
        ? COLOR_BLACK_TEXT
        : variant === 'white'
        ? COLOR_BLACK_TEXT
        : COLOR_WHITE};
  }
  ${({ spin }) => spin && `background:${COLOR_GRAY_3}  !important; border: 1px solid ${COLOR_GRAY_3} !important;`}
  ${variantStyled({
    prop: 'scale',
    variants: scaleVariants,
  })}
  ${layout}
  ${space}
  ${typography}
  ${flexbox}
`
