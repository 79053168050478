import { MouseEvent, ReactNode } from 'react'
import { SpaceProps, BackgroundProps } from 'styled-system'

export type AlertTheme = {
  background: string
}

export const alertVariants = {
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
} as const

export type Variants = typeof alertVariants[keyof typeof alertVariants]

export interface AlertWrapperProps extends SpaceProps, BackgroundProps {}
export interface AlertProps {
  variant?: Variants
  title?: string
  children?: ReactNode
  onClick?: (evt: MouseEvent<HTMLButtonElement>) => void
  buttonText?: string
  handleClose?: (evt: MouseEvent<HTMLButtonElement>) => void
  icon?: ReactNode
  propsWrapper?: AlertWrapperProps
  actions?: ReactNode
}

export const toastTypes = {
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
}

export type Types = typeof toastTypes[keyof typeof toastTypes]

export interface Toast {
  id: string
  type: Types
  title: string
  description?: ReactNode
  icon?: ReactNode
  actions?: ReactNode
}

export interface ToastContainerProps {
  toasts: Toast[]
  stackSpacing?: number
  ttl?: number
  onRemove: (id: string) => void
  propsWrapper?: SpaceProps
  propsWrapperAlert?: AlertWrapperProps
}

export interface ToastProps {
  toast: Toast
  onRemove: ToastContainerProps['onRemove']
  ttl: number
  style?: Partial<CSSStyleDeclaration>
  icon?: ReactNode
  propsWrapperAlert?: AlertWrapperProps
}
