import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { getToken } from 'utils/session'
import { ArtworkState, ARTWORK_STATUS } from '../types'

const initialState: ArtworkState = {
  artwork: {},
  fetching: true,
  artworkTrade: [],
  modalOfferOpen: false,
  needKyc: false,
  servises: [],
}

export const fetchArtworkData = createAsyncThunk<{ data: any }, { id: string }>('Artwork/page', async ({ id }) => {
  const response = await axios(`${process.env.REACT_APP_API}/artwork/${id}`)
  if (response?.status === 200) {
    const { data } = response
    return { data }
  }
  return {
    data: {},
  }
})

export const fetchArtworkTrading = createAsyncThunk<{ orders: any[] }>('Artwork/trading', async () => {
  const response = await axios(
    `${process.env.REACT_APP_API}/artwork/explore?decimals=18&limit=4&name=Ethereum&offset=0&orderBy=createdAt&orderSort=desc&priceRangeFrom=&priceRangeTo=&tic`,
  )
  if (response?.status === 200) {
    const { orders } = response?.data
    return { orders }
  }
  return {
    orders: [],
  }
})

export const authSlice = createSlice({
  name: 'Collector',
  initialState,
  reducers: {
    setArtworkState: (state, action: PayloadAction<{ artwork: any; fetching: boolean }>) => {
      const { artwork, fetching } = action.payload
      state = { ...state, artwork, fetching }
      return state
    },
    setArtworkStatus: (state, action: PayloadAction<ARTWORK_STATUS>) => {
      state.artwork = { ...state.artwork, status: action.payload }
      return state
    },
    setModalOfferOpen: (state, action: PayloadAction<{ modalOfferOpen: boolean }>) => {
      state.modalOfferOpen = action.payload.modalOfferOpen
      return state
    },
    setNeedKyc: (state, action: PayloadAction<boolean>) => {
      state.needKyc = action.payload
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArtworkData.fulfilled, (state, action) => {
      state.fetching = state.fetching && false
      return { ...state, artwork: action.payload.data }
    })
    builder.addCase(fetchArtworkTrading.fulfilled, (state, action) => {
      const { orders } = action.payload
      state.artworkTrade = orders
      state.fetching = state.fetching && false
    })
  },
})

// Actions
export const { setArtworkState, setArtworkStatus, setModalOfferOpen, setNeedKyc } = authSlice.actions

export default authSlice.reducer
