import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INPUTS_CREATE_PROFILE, ProfileEditCollectorState } from '../types'

const initialState: ProfileEditCollectorState = {
  [INPUTS_CREATE_PROFILE.FULL_NAME]: '',
  [INPUTS_CREATE_PROFILE.USER_NAME]: '',
  [INPUTS_CREATE_PROFILE.EMAIL]: '',
  [INPUTS_CREATE_PROFILE.BIO]: '',
  [INPUTS_CREATE_PROFILE.FACEBOOK]: '',
  [INPUTS_CREATE_PROFILE.SITE]: '',
  [INPUTS_CREATE_PROFILE.TWITTER]: '',
  [INPUTS_CREATE_PROFILE.INSTAGRAM]: '',
  id: '',
}
export const profileEditCollectorSlice = createSlice({
  name: 'ProfieEdit',
  initialState,
  reducers: {
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state[name] = value
      return state
    },
    setFetchEditCollectorData: (state, action: PayloadAction<any>) => {
      state = action.payload
      return state
    },
  },
})

// Actions
export const { setValueInput, setFetchEditCollectorData } = profileEditCollectorSlice.actions

export default profileEditCollectorSlice.reducer
