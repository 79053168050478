const COLOR_WHITE = '#ffffff'
export const COLOR_BLACK = '#000000'
export const COLOR_BLACK_RGBA = 'rgba(0,0,0,0.5)'
export const COLOR_BLACK_RGBA_2 = 'rgba(0,0,0,0.7)'

export const COLOR_BLACK_TEXT = '#181818'
export const COLOR_GRAY_TEXT = '#8B8B8B'
export const COLOR_GRAY_TEXT_2 = '#bcbcbc'

export const COLOR_GRAY = '#DEDEDE'
export const COLOR_GRAY_2 = '#5F5F5F'
export const COLOR_GRAY_3 = '#f4f4f4'
export const COLOR_GRAY_4 = '#EDEDED'
export const COLOR_GRAY_5 = '#d1d1d1'
export const COLOR_GRAY_6 = '#8d8d8d'
export const COLOR_GRAY_7 = '#808080'
export const COLOR_GRAY_8 = '#f2f1ec'
export const COLOR_GRAY_10 = '#9e9e9e'

export const COLOR_GRAY_RGBA = 'rgba(0, 0, 0, .5)'

export const COLOR_YELLOW = '#FFF9C1'

export const COLOR_GREEN = '#31833E'

export const COLOR_ORANGE = '#FF6B00'
export const COLOR_RED = '#CB0000'
export const COLOR_RED_2 = '#B71111'
export const COLOR_RED_3 = '#852727'

export const GRADIENT_DARK = 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 60.85%)'

export const COLOR_SHADOW = '2px 6px 40px rgba(0, 0, 0, 0.1)'
export const COLOR_SHADOW_2 = ' 2px 6px 24px rgba(0, 0, 0, 0.08)'
export const COLOR_SHADOW_3 = '2px 66px 130px rgba(0, 0, 0, 0.6)'

export const COLOR_FILTER = 'drop-shadow(0px 54px 24px rgba(0, 0, 0, 0.25))'

export const COLOR_PURPLE = '#6330D0'

export default COLOR_WHITE
