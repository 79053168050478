import CheckIcon from 'assets/icons/CheckIcon'
import CloseIcon from 'assets/icons/CloseIcon'
import COLOR_WHITE, {
  COLOR_BLACK_TEXT,
  COLOR_GRAY_3,
  COLOR_GREEN,
  COLOR_RED_2,
  COLOR_YELLOW,
} from 'constants/styles/colors'
import React, { ReactNode, useEffect, useState } from 'react'
import { NOTICE_VARIANTS } from 'state/types'
import styled from 'styled-components'

interface NoticeVariantProps {
  open?: boolean
  variant: NOTICE_VARIANTS
  title: string
  actions?: ReactNode
  id: number
  handleClose: (e: any) => void
}

const bgcolors = [
  {
    variant: NOTICE_VARIANTS.ERROR,
    color: COLOR_RED_2,
  },
  {
    variant: NOTICE_VARIANTS.SUCCESS,
    color: COLOR_GREEN,
  },
  {
    variant: NOTICE_VARIANTS.WARNING,
    color: COLOR_YELLOW,
  },
  {
    variant: NOTICE_VARIANTS.INFO,
    color: COLOR_GRAY_3,
  },
]

const icons = [
  {
    variant: NOTICE_VARIANTS.ERROR,
    icon: <CheckIcon />,
  },
  {
    variant: NOTICE_VARIANTS.SUCCESS,
    icon: <CheckIcon />,
  },
  {
    variant: NOTICE_VARIANTS.WARNING,
    icon: <CheckIcon />,
  },
  {
    variant: NOTICE_VARIANTS.INFO,
    icon: <CheckIcon />,
  },
]

const colors = [
  {
    variant: NOTICE_VARIANTS.ERROR,
    color: COLOR_WHITE,
  },
  {
    variant: NOTICE_VARIANTS.SUCCESS,
    color: COLOR_WHITE,
  },
  {
    variant: NOTICE_VARIANTS.WARNING,
    color: COLOR_BLACK_TEXT,
  },
  {
    variant: NOTICE_VARIANTS.INFO,
    color: COLOR_BLACK_TEXT,
  },
]

export default function NoticeVariant({ variant, title, actions, handleClose, id }: NoticeVariantProps) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 10)
  }, [])

  return (
    <Wrapper bg={variant && bgcolors.find((el) => el.variant === variant).color} open={open}>
      <Side>
        {variant && icons.find((el) => el.variant === variant).icon}
        <Title color={variant && colors.find((el) => el.variant === variant).color}>
          id={id} {title}
        </Title>
      </Side>
      <Side>
        {actions}
        <Button value={id} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Side>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ open: boolean; bg: string }>`
  width: 100%;
  position: fixed;
  top: ${({ open }) => (open ? 0 : '-100%')};
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ open }) => (open ? '16px' : 0)};
  height: ${({ open }) => (open ? 'auto' : 0)};
  max-height: ${({ open }) => (open ? '1000px' : 0)};
  transition: all 0.2s ease-out;
  background: ${({ bg }) => bg};
  overflow: hidden;
`
const Side = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.div<{ color: string }>`
  margin-left: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  color: ${({ color }) => color};
`
const Button = styled.button`
  margin-left: 16px;
  & svg {
    pointer-events: none;
  }
`
