import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { CollectorState, CollectorTabs } from '../types'

const initialState: CollectorState = {
  tabValue: CollectorTabs.RELEASE,
  artworks: { orders: [] },
  isFetching: true,
  activity: [],
}

export const fetchArtworksData = createAsyncThunk<{ data: any }, { id: string }>(
  'Collector/artworks',
  async ({ id }) => {
    const response = await axios(`${process.env.REACT_APP_API}/artwork/collector/${id}`)
    if (response?.status === 200) {
      const { data } = response
      return { data }
    }
    return {
      data: { orders: [] },
    }
  },
)

export const fetchActivityData = createAsyncThunk<{ data: any }, { id: string }>(
  'Collector/activity',
  async ({ id }) => {
    const response = await axios(`${process.env.REACT_APP_API}/profile/collector/${id}/activity`)
    if (response?.status === 200) {
      const { data } = response
      return { data }
    }
    return {
      data: [],
    }
  },
)

export const collectorSlice = createSlice({
  name: 'Collector',
  initialState,
  reducers: {
    setTabValue: (state, action: PayloadAction<CollectorTabs>) => {
      state = { ...state, tabValue: action.payload }
      return state
    },
    setCollectorState: (
      state,
      action: PayloadAction<{ isFetching: boolean; artworks: { orders: [] }; activity: [] }>,
    ) => {
      state = { ...state, ...action.payload }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArtworksData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.artworks = data
      state.isFetching = state.isFetching && false
    })
    builder.addCase(fetchActivityData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.activity = data
      state.isFetching = state.isFetching && false
    })
  },
})

// Actions
export const { setTabValue, setCollectorState } = collectorSlice.actions

export default collectorSlice.reducer
