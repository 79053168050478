import React from 'react'
import styled from 'styled-components'
import COLOR_WHITE, {
  COLOR_BLACK_TEXT,
  COLOR_GRAY_2,
  COLOR_GRAY_TEXT,
  COLOR_GREEN,
  COLOR_RED_2,
  COLOR_YELLOW,
} from 'constants/styles/colors'
import CheckIcon from 'assets/icons/CheckIcon'
import WarningIcon from 'assets/icons/WarningIcon'
import InfoIcon from 'assets/icons/InfoIcon'
import { background } from 'styled-system'
import CloseIcon from 'assets/icons/CloseIcon'
import { AlertProps, alertVariants } from './types'

const StyledAlert = styled.div<{ bg: string; variant: string }>`
  width: 100%;
  max-width: 375px;
  position: relative;
  z-index: 5;
  transition: 0.3s ease;
  padding: 20px;
  /* ${background} */
  background: ${({ bg }) => bg};
  ${({ variant }) =>
    variant === alertVariants.WARNING &&
    `
    & svg {
      & path {
      fill: ${COLOR_GRAY_TEXT};
    }}
  `}
`

const getIcon = (variant: AlertProps['variant'] = alertVariants.SUCCESS) => {
  switch (variant) {
    case alertVariants.WARNING:
      return <WarningIcon />
    case alertVariants.SUCCESS:
      return <CheckIcon />
    case alertVariants.INFO:
      return <InfoIcon />
    case alertVariants.ERROR:
      return <WarningIcon />
    default:
      return <></>
  }
}

const bgColors = {
  [alertVariants.SUCCESS]: COLOR_GREEN,
  [alertVariants.INFO]: COLOR_GRAY_TEXT,
  [alertVariants.WARNING]: COLOR_YELLOW,
  [alertVariants.ERROR]: COLOR_RED_2,
}

const Button = styled.button`
  position: absolute;
  padding: 0;
  top: 20px;
  right: 20px;
  &:disabled {
    cursor: default;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const SetButton = ({ onClick }: AlertProps) => {
  return (
    <Button onClick={onClick}>
      <CloseIcon />
    </Button>
  )
}

const Alert: React.FC<AlertProps> = ({ title, children, variant, onClick, icon, propsWrapper, actions }) => {
  return (
    <StyledAlert variant={variant} bg={bgColors[variant]} {...propsWrapper}>
      <Row>
        {getIcon(variant)}
        {title && <Text variant={variant}>{title}</Text>}
      </Row>
      <Message variant={variant}>{children}</Message>
      <SetButton icon={icon} variant={variant} onClick={onClick} />
      <Actions>{actions}</Actions>
    </StyledAlert>
  )
}

export default Alert

const Text = styled.div<{ variant: string }>`
  margin-left: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: ${({ variant }) => (variant === alertVariants.WARNING ? COLOR_BLACK_TEXT : COLOR_WHITE)};
`
const Message = styled.div<{ variant: string }>`
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ variant }) => (variant === alertVariants.WARNING ? COLOR_GRAY_2 : COLOR_WHITE)};
  opacity: 0.8;
`
const Actions = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
`
