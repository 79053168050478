import { ThunkAction } from 'redux-thunk'
import { AnyAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { TYPES_ACCOUNT } from 'utils/session'

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, AnyAction>

export enum MuseumTabs {
  RELEASE = 1,
  ACTIVITY = 2,
}

export enum CollectorTabs {
  RELEASE = 1,
  ARTWORKS = 2,
  COLLECTORS = 3,
}

export enum INPUTS_DATE {
  START = 'start',
  END = 'end',
}

export enum ProfileTabs {
  COLLECTIONS = 1,
  FAVORITS = 2,
  FOLLOWING = 3,
  ACTIVITY = 4,
}

export enum ARTWORK_STATUS {
  NO_ORDER = 0,
  BUY_NOW = 1,
  ON_AUCTION = 2,
  HAS_OFFERS = 3,
}

export enum MARTKETPLACE_ORDER_STATUS {
  NULL = 0,
  OPEN = 1,
  CANCELLED = 2,
  EXECUTED = 3,
  ANY = 4,
}

export enum INPUTS_CREATE_PROFILE {
  USER_NAME = 'username',
  FULL_NAME = 'fullName',
  DISPLAY_NAME = 'displayName',
  EMAIL = 'email',
  BIO = 'bio',
  SITE = 'site',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
}

export enum NOTICE_VARIANTS {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

export enum INPUTS_IVITE {
  NAME = 'name',
  COUNTRY = 'country',
  CITY = 'city',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  EMAIL = 'email',
  SITE = 'site',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
}

export enum INPUTS_PROFILE_EDIT {
  NAME = 'name',
  COUNTRY = 'country',
  CITY = 'city',
  DESCRIPTION = 'description',
  SITE = 'site',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  PINTEREST = 'pinterest',
  YOUTUBE = 'youtube',
  SPOTIFY = 'spotify',
}

export enum RELEASE_CREATE_TYPE {
  NONE = 0,
  ONE = 1,
  SERIES = 3,
}

export enum INPUTS_REALESE_CREATE {
  MASTER = 'master',
  ARTWORK_NAME = 'artworkName',
  PRICE = 'price',
  CURRENCY = 'currency',
  FEES = 'fees',
  ABOUT = 'about',
  DATED = 'dated',
  OBJECT_NAME = 'objectName',
  TECHNIQ = 'techniq',
  INVENTORY_NUMBER = 'inventoryNumber',
  MATERIAL = 'material',
  DMENSIONS = 'dmensions',
  SIGNED = 'signed',
  PERIOD = 'period',
  MOVEMENT = 'movement',
  COUNTRY = 'country',
  SUBJECT_MATTER = 'subjectMatter',
  ADDITIONAL = 'additional',
  CATEGORY = 'category',
  TITLE_PRIMARY = 'titlePrimary',
  TITLE_SECONDARY = 'titleSecondary',
  IMAGE = 'img',
  ARTWORK_IMAGE = 'artworkImg',
  ABOUT_ARTWORK = 'aboutArtwork',
  DATE_START = 'dateStart',
  DATE_END = 'dateEnd',
  MIN_BET = 'minBet',
  PRICE_USD = 'priceUsd',
}

export enum ReleaseTypeSale {
  FIX_PRICE,
  AUCTION,
}

export type TranslatableText =
  | string
  | {
      key: string
      data?: {
        [key: string]: string | number
      }
    }

export interface ReleasesData {
  blockNumber: string
  blockchain: string
  createdAt: string
  dateStart: null
  description: string
  documents: null
  fees: null
  id: string
  img: string
  ipfs: string
  isActive: true
  master: string
  smartContract: string
  title: string
  tokenName: string
  tokenSymbol: string
  txHash: null
  type: number
  updatedAt: string
}
export interface ArtworkData {
  blockNumber: number
  blockNumberTimestamp: number
  blockchain: string
  cancelUser: null | string | number
  category: string
  country: null | string | number
  createdAt: string
  dateEnd: null | string | number
  dateStart: null | string | number
  dated: string
  description: string
  dimensions: null | string | number
  executeUser: string
  fee: string
  hash: string
  id: string
  img: string
  inventoryNumber: null | string | number
  ipfsHash: null | string | number
  isActive: boolean
  isMinted: boolean
  lastEventTimestamp: number
  mainSettlementToken: string
  market: null | string | number
  materials: null | string | number
  metadataRaw: null | string | number
  movement: string
  name: string
  orderId: null | string | number
  orderStatus: number
  otherSettlementTokens: null | string | number
  owner: any
  period: string
  price: null | string | number
  properties: null | string | number
  seller: string
  status: number
  tokenAmount: number
  tokenContract: string
  tokenId: number
  tokenType: string
  updatedAt: string
}
export interface MuseumData {
  city: string
  country: string
  createdAt: string
  description: string
  email: string
  facebook: string
  firstName: string
  gallery: string
  id: string
  image: string
  instagram: string
  lastName: string
  name: string
  phone: string
  site: string
  status: number
  twitter: string
  updatedAt: string
}
export interface HeaderState {
  inputValue: string
  inputOpen: boolean
  menuOpen: boolean
  modalOpen: boolean
  profileOpen: boolean
  modalMuseumOpen: boolean
  isAuth: number
  modalErrorNetworkOpen: boolean
  modalNoWalletOpen: boolean
  searchData: {
    museums: MuseumData[]
    releases: ReleasesData[]
    artworks: ArtworkData[]
  }
  isFetchingSearch: boolean
}

export interface Landing {
  trends: any[]
  isFetchingTrends: boolean
}

export interface MusemState {
  tabValue: MuseumTabs
  popular: any[]
  isFetching: boolean
  collectors: any[]
  filterOptions: {
    orderBy: string
    orderSort: string
  }
  isFetchingMuseumList: boolean
  fetchDataMuseumList: any
}

export interface MarketState {
  fetchData: any
  fetching: boolean
  filterOpen: boolean
  checkedOptions: {
    status: string[]
    category: string[]
    market: string[]
    movement: string[]
    period: string[]
    materials: string[]
  }
  selectedOptions: {
    limit: number
    offset: number
    orderBy: string
    orderSort: string
    status: string
    ticker: string
    name: string
    decimals: number
    priceRangeFrom: string
    priceRangeTo: string
    museumId: string
  }
  filterList: {
    status: string[]
    category: string[]
    movement: string[]
    period: string[]
    materials: string[]
    market: string[]
  }
  countriy: { name: string; code: string }[]
  fetchCountriy: boolean
  fetchFilter: boolean
}

export interface ReleaseState {
  openInfo: boolean
  releaseList: any[]
  isFetching: boolean
  release: any
}

export interface ProfileState {
  tabValue: ProfileTabs
}

export interface CollectorState {
  tabValue: CollectorTabs
  artworks: { orders: any[] }
  isFetching: boolean
  activity: any[]
}

export interface ProfileCreateState {
  [INPUTS_CREATE_PROFILE.USER_NAME]: string
  [INPUTS_CREATE_PROFILE.FULL_NAME]: string
  [INPUTS_CREATE_PROFILE.DISPLAY_NAME]: string
  [INPUTS_CREATE_PROFILE.EMAIL]: string
  [INPUTS_CREATE_PROFILE.BIO]?: string
  [INPUTS_CREATE_PROFILE.SITE]?: string
  [INPUTS_CREATE_PROFILE.FACEBOOK]?: string
  [INPUTS_CREATE_PROFILE.TWITTER]?: string
  [INPUTS_CREATE_PROFILE.INSTAGRAM]?: string
  step: number
  img: any[] | string
  id: string
}

export interface NoticeState {
  open: boolean
  title: string
  variant: NOTICE_VARIANTS
  actions?: ReactNode
}

export type InputPlaceHolderValueProps =
  | INPUTS_CREATE_PROFILE
  | INPUTS_REALESE_CREATE
  | INPUTS_IVITE
  | INPUTS_PROFILE_EDIT
  | INPUTS_CREATE_MUSEUM_FRIENDS

export interface ProfileEditCollectorState {
  [INPUTS_CREATE_PROFILE.FULL_NAME]: string
  [INPUTS_CREATE_PROFILE.USER_NAME]: string
  [INPUTS_CREATE_PROFILE.EMAIL]: string
  [INPUTS_CREATE_PROFILE.BIO]?: string
  [INPUTS_CREATE_PROFILE.SITE]?: string
  [INPUTS_CREATE_PROFILE.FACEBOOK]?: string
  [INPUTS_CREATE_PROFILE.TWITTER]?: string
  [INPUTS_CREATE_PROFILE.INSTAGRAM]?: string
  id?: string
}

export interface ProfileEditMuseumState {
  [INPUTS_PROFILE_EDIT.NAME]: string
  [INPUTS_PROFILE_EDIT.COUNTRY]: string
  [INPUTS_PROFILE_EDIT.CITY]: string
  [INPUTS_PROFILE_EDIT.DESCRIPTION]?: string
  [INPUTS_PROFILE_EDIT.SITE]?: string
  [INPUTS_PROFILE_EDIT.FACEBOOK]?: string
  [INPUTS_PROFILE_EDIT.TWITTER]?: string
  [INPUTS_PROFILE_EDIT.INSTAGRAM]?: string
  [INPUTS_PROFILE_EDIT.PINTEREST]?: string
  [INPUTS_PROFILE_EDIT.SPOTIFY]?: string
  [INPUTS_PROFILE_EDIT.YOUTUBE]?: string
  id?: string
  img?: any[] | string
  [INPUTS_IVITE.FIRST_NAME]: string
  [INPUTS_IVITE.LAST_NAME]: string
  [INPUTS_IVITE.PHONE]: string
  [INPUTS_IVITE.EMAIL]: string
  gallery?: any[] | string
}

export interface InviteState extends ProfileEditMuseumState {
  step: number
}

export type Artwork = {
  [INPUTS_REALESE_CREATE.MASTER]: string
  [INPUTS_REALESE_CREATE.ARTWORK_NAME]: string
  [INPUTS_REALESE_CREATE.PRICE]: string
  [INPUTS_REALESE_CREATE.CURRENCY]: string
  [INPUTS_REALESE_CREATE.FEES]: string
  [INPUTS_REALESE_CREATE.ABOUT]: string
  [INPUTS_REALESE_CREATE.DATED]: string
  [INPUTS_REALESE_CREATE.OBJECT_NAME]: string
  [INPUTS_REALESE_CREATE.INVENTORY_NUMBER]: string
  [INPUTS_REALESE_CREATE.MATERIAL]: string
  [INPUTS_REALESE_CREATE.DMENSIONS]: string
  [INPUTS_REALESE_CREATE.SIGNED]: string
  [INPUTS_REALESE_CREATE.PERIOD]: string
  [INPUTS_REALESE_CREATE.MOVEMENT]: string
  [INPUTS_REALESE_CREATE.COUNTRY]: string
  [INPUTS_REALESE_CREATE.SUBJECT_MATTER]: string
  [INPUTS_REALESE_CREATE.ADDITIONAL]: string
  [INPUTS_REALESE_CREATE.TECHNIQ]: string
  [INPUTS_REALESE_CREATE.CATEGORY]: string
  img: any[] | string
  docs: any[]
}

export type ReleaseCreateInputValues = {
  [INPUTS_REALESE_CREATE.MASTER]: string
  [INPUTS_REALESE_CREATE.ARTWORK_NAME]: string
  [INPUTS_REALESE_CREATE.PRICE]: string
  [INPUTS_REALESE_CREATE.CURRENCY]: string
  [INPUTS_REALESE_CREATE.FEES]: string
  [INPUTS_REALESE_CREATE.ABOUT]: string
  [INPUTS_REALESE_CREATE.DATED]: string
  [INPUTS_REALESE_CREATE.OBJECT_NAME]: string
  [INPUTS_REALESE_CREATE.INVENTORY_NUMBER]: string
  [INPUTS_REALESE_CREATE.MATERIAL]: string
  [INPUTS_REALESE_CREATE.DMENSIONS]: string
  [INPUTS_REALESE_CREATE.SIGNED]: string
  [INPUTS_REALESE_CREATE.PERIOD]: string
  [INPUTS_REALESE_CREATE.MOVEMENT]: string
  [INPUTS_REALESE_CREATE.COUNTRY]: string
  [INPUTS_REALESE_CREATE.SUBJECT_MATTER]: string
  [INPUTS_REALESE_CREATE.ADDITIONAL]: string
  [INPUTS_REALESE_CREATE.TECHNIQ]: string
  [INPUTS_REALESE_CREATE.CATEGORY]: string
  [INPUTS_REALESE_CREATE.ARTWORK_IMAGE]: File[]
  [INPUTS_REALESE_CREATE.TITLE_PRIMARY]: string
  [INPUTS_REALESE_CREATE.TITLE_SECONDARY]: string
  [INPUTS_REALESE_CREATE.ABOUT_ARTWORK]: string
  [INPUTS_REALESE_CREATE.IMAGE]: string
  [INPUTS_REALESE_CREATE.DATE_START]: string
  [INPUTS_REALESE_CREATE.DATE_END]: string
  [INPUTS_REALESE_CREATE.MIN_BET]: string
  [INPUTS_REALESE_CREATE.PRICE_USD]: string
  docs: any
  ifps: any
}

export interface ReleaseCreateState {
  type: RELEASE_CREATE_TYPE
  step: number
  id: string
  tabValue: ReleaseCreateTabs
  inputValues: ReleaseCreateInputValues
  acceptTerm: boolean
  saleType: ReleaseTypeSale
}

export interface ProfileSettingsState {
  [INPUTS_IVITE.FIRST_NAME]: string
  [INPUTS_IVITE.LAST_NAME]: string
  [INPUTS_IVITE.PHONE]: string
  [INPUTS_IVITE.EMAIL]: string
  id: string
}

export interface AuthState {
  type: TYPES_ACCOUNT | null
  token: string
  account: any
  load: boolean
}

export interface ArtworkState {
  artwork: any & { status: ARTWORK_STATUS }
  fetching: boolean
  artworkTrade: any[]
  modalOfferOpen: boolean
  needKyc: boolean
  servises: []
}

export enum FriendsCreateTabs {
  DESCRIPTION = 1,
  BENEFITS = 2,
  REWARDS = 3,
  OPTIONS = 4,
  DROP = 5,
}

export enum ReleaseCreateTabs {
  PROMO,
  DESCRIPTION,
  OPTIONS,
  DROP,
}

export enum INPUTS_CREATE_MUSEUM_FRIENDS {
  PRIMARY_TITLE = 'titlePrimary',
  SECONDARY_TITLE = 'titleSecondary',
  ABOUT = 'about',
  IMAGE = 'img',
  BENEFITS_CONDITION = 'benefitsCondition',
  BENEFITS_SERVICE = 'benefitsService',
  REWARDS_ABOUT = 'rewardsAbout',
  REWARDS_LIST = 'rewards',
  ACTIVITIES_LIST = 'activitiesList',
  CIRCULATION = 'circulation',
  PRICE = 'price',
  PERPETUAL_ROYALTY = 'perpetualRoyalty',
  CHECKING_AGREE = 'checkingAgree',
}

export type InputValueMuseumFriends = {
  [INPUTS_CREATE_MUSEUM_FRIENDS.PRIMARY_TITLE]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.SECONDARY_TITLE]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.ABOUT]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.IMAGE]: any[] | string
  [INPUTS_CREATE_MUSEUM_FRIENDS.BENEFITS_CONDITION]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.BENEFITS_SERVICE]: any[]
  [INPUTS_CREATE_MUSEUM_FRIENDS.REWARDS_ABOUT]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.REWARDS_LIST]: any[]
  [INPUTS_CREATE_MUSEUM_FRIENDS.ACTIVITIES_LIST]: any[]
  [INPUTS_CREATE_MUSEUM_FRIENDS.CIRCULATION]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.PRICE]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.PERPETUAL_ROYALTY]: string
  [INPUTS_CREATE_MUSEUM_FRIENDS.CHECKING_AGREE]: boolean
}

export interface MusemFriendsState {
  tabValue: FriendsCreateTabs
  inputValues: InputValueMuseumFriends
  benefitsTabSaved?: boolean
  rewardTabSaved?: boolean
  services?: any
  stateChanged: boolean
}

export interface State {
  header: HeaderState
  museum: MusemState
  market: MarketState
  release: ReleaseState
  profile: ProfileState
  collector: CollectorState
  profileCreate: ProfileCreateState
  notice: NoticeState
  invite: InviteState
  profileEditMuseum: ProfileEditMuseumState
  releaseCreate: ReleaseCreateState
  profileSettings: ProfileSettingsState
  profileEditCollector: ProfileEditCollectorState
  auth: AuthState
  artwork: ArtworkState
  museumFriends: MusemFriendsState
  landing: Landing
}
