import React from 'react'
import useToast from 'hooks/useToast'
import ToastContainer from './ToastContainer'

interface Props {
  propsWrapper?: any
  propsAlert?: any
}

const ToastListener = ({ propsWrapper, propsAlert }: Props) => {
  const { toasts, remove } = useToast()

  const handleRemove = (id: string) => remove(id)

  return (
    <ToastContainer
      toasts={toasts}
      onRemove={handleRemove}
      propsWrapper={propsWrapper}
      propsWrapperAlert={propsAlert}
    />
  )
}

export default ToastListener
