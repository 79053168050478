import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from 'utils/web3React'
import { Provider } from 'react-redux'
import store from 'state'
import { ToastsProvider } from 'context/ToastsContext/Provider'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ToastsProvider>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
          <App />
        </Provider>
      </Web3ReactProvider>
    </ToastsProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
