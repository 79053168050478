enum ROUTES {
  HOME_URL = '/',
  RELEASES_URL = '/releases_legacy',
  MUSEUMS_LIST_URL = '/museums',
  MUSEUM_RELEASE_URL = '/releases',
  MARKET_URL = '/market',
  COLLECTOR_URL = '/collector',
  RELEASE_URL = '/release',
  SIGNIN_URL = '/signin',
  ARTWORK_URL = '/artwork',
  PROFILE_URL = '/collector',
  PROFILE_CREATE_URL = '/profile-create',
  INVITATION_URL = '/invitation',
  PROFILE_EDIT_URL = '/profile-edit',
  RELEASE_CREATE_URL = '/release-create',
  PROFILE_SETTINGS_URL = '/profile-settings',
  PROFILE_SETTINGS_MUSEUM_URL = '/profile-museum-settings',
  SEARCH_PAGE = '/search',
  FRIENDS_CREATE = '/friends-create',
  FRIENDS_MUSEUM = '/pass',
  LANDING_URL = '/landing',
  KYC_URL = '/kyc',
  RELEASE_EDIT_URL = '/release-edit',
  FRIENDS_EDIT = '/friends-edit',
  FAQ_PAGE = '/faq',
  PRIVACY_PAGE = '/privacy',
  TERMS_PAGE = '/terms',
  HELP_PAGE = '/help',
  COOKIE_PAGE = '/cookies',
  COOKIES_POLICY_PAGE = '/cookies-policy',
}

export const OFAM_ID = 'cde02e0f-eec2-4726-a5c3-c7bb1c48c53e'

export default ROUTES
