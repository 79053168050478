import Overlay from 'components/Overlay/Overlay'
import mediaQueries from 'constants/styles/mediaQueries'
import React from 'react'
import styled from 'styled-components'
import LOADER from '../../assets/loader.gif'

export default function Suspense() {
  return (
    <Wrapper>
      {/* <Loader /> */}
      <img width="170px" height="170px" alt="" src={LOADER} />
      <Overlay open />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: calc(100vh - 370px);
  height: 100vh;
  & img {
    height: 170px;
    width: 170px;
    z-index: 11;
  }
  ${mediaQueries.lg} {
    min-height: calc(100vh - 490px);
  }
`
