import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { HeaderState } from '../types'

const initialState: HeaderState = {
  inputValue: '',
  inputOpen: false,
  menuOpen: false,
  modalOpen: false,
  profileOpen: false,
  modalMuseumOpen: false,
  isAuth: 0,
  modalErrorNetworkOpen: false,
  modalNoWalletOpen: false,
  searchData: {
    museums: [],
    releases: [],
    artworks: [],
  },
  isFetchingSearch: true,
}

export const fetchSearchData = createAsyncThunk<{ data: any }, string>('Header/searchData', async (query) => {
  const response = await axios(`${process.env.REACT_APP_API}/search?${query}`)
  if (response?.status === 200) {
    return { data: response.data }
  }
  return {
    data: {},
  }
})

export const headerSlice = createSlice({
  name: 'Header',
  initialState,
  reducers: {
    setInputValue: (state, action: PayloadAction<HeaderState>) => {
      const { inputValue } = action.payload
      state = { ...state, inputValue }
      return state
    },
    setInputOpen: (state, action: PayloadAction<HeaderState>) => {
      const { inputOpen } = action.payload
      state = { ...state, inputOpen }
      return state
    },
    setMenuOpen: (state, action: PayloadAction<HeaderState>) => {
      const { menuOpen } = action.payload
      state = { ...state, menuOpen }
      return state
    },
    setModalOpen: (state, action: PayloadAction<HeaderState>) => {
      const { modalOpen } = action.payload
      state = { ...state, modalOpen }
      return state
    },
    setMenuProfileOpen: (state, action: PayloadAction<HeaderState>) => {
      const { profileOpen } = action.payload
      state = { ...state, profileOpen }
      return state
    },
    setModalMuseumOpen: (state, action: PayloadAction<HeaderState>) => {
      const { modalMuseumOpen } = action.payload
      state = { ...state, modalMuseumOpen }
      return state
    },
    setAuth: (state, action: PayloadAction<number>) => {
      state.isAuth = action.payload
    },
    setModalErrorNetworkOpen: (state, action: PayloadAction<HeaderState>) => {
      const { modalErrorNetworkOpen } = action.payload
      state = { ...state, modalErrorNetworkOpen }
      return state
    },
    setModalNoWalletOpen: (state, action: PayloadAction<HeaderState>) => {
      const { modalNoWalletOpen } = action.payload
      state = { ...state, modalNoWalletOpen }
      return state
    },
    setFetchingSearch: (state, action: PayloadAction<HeaderState>) => {
      const { isFetchingSearch } = action.payload
      state = { ...state, isFetchingSearch }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.searchData = data
      state.isFetchingSearch = state.isFetchingSearch && false
    })
  },
})

// Actions
export const {
  setInputValue,
  setInputOpen,
  setModalOpen,
  setMenuOpen,
  setMenuProfileOpen,
  setModalMuseumOpen,
  setAuth,
  setModalErrorNetworkOpen,
  setModalNoWalletOpen,
  setFetchingSearch,
} = headerSlice.actions

export default headerSlice.reducer
