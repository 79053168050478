import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { MusemState, MuseumTabs } from '../types'

const initialState: MusemState = {
  tabValue: MuseumTabs.RELEASE,
  popular: [],
  isFetching: true,
  collectors: [],
  filterOptions: {
    orderBy: 'newest',
    orderSort: 'desc',
  },
  isFetchingMuseumList: true,
  fetchDataMuseumList: {},
}

export const fetchPopularData = createAsyncThunk<{ data: any }>('Museum/popular', async () => {
  const response = await axios(`${process.env.REACT_APP_API}/museum/popular`)
  if (response?.status === 200) {
    const data = response.data.map((item) => ({ ...item, value: item.code }))
    return { data }
  }
  return {
    data: {},
  }
})

export const fetchCollectorsData = createAsyncThunk<{ data: any }, { id: string }>(
  'Museum/collectors',
  async ({ id }) => {
    const response = await axios(`${process.env.REACT_APP_API}/museum/${id}/collector`)
    if (response?.status === 200) {
      const data = response.data.map((item) => ({ ...item, value: item.code }))
      return { data }
    }
    return {
      data: [],
    }
  },
)

export const museumSlice = createSlice({
  name: 'Museum',
  initialState,
  reducers: {
    setTabValue: (state, action: PayloadAction<MuseumTabs>) => {
      state = { ...state, tabValue: action.payload }
      return state
    },
    setFiltersList: (state, action: PayloadAction<any>) => {
      state.filterOptions = { ...state.filterOptions, [action.payload.name]: action.payload.value }
    },
    fetchDataAction: (state, action: PayloadAction<boolean>) => {
      state = { ...state, fetchDataMuseumList: action.payload }
      return state
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state = { ...state, isFetchingMuseumList: action.payload }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPopularData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.popular = data
      state.isFetching = state.isFetching && false
    })
    builder.addCase(fetchCollectorsData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.collectors = data
      state.isFetchingMuseumList = state.isFetchingMuseumList && false
    })
  },
})

// Actions
export const { setTabValue, setFiltersList, fetchDataAction, setFetching } = museumSlice.actions

export default museumSlice.reducer
