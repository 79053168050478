import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INPUTS_CREATE_PROFILE, ProfileCreateState } from '../types'

const initialState: ProfileCreateState = {
  [INPUTS_CREATE_PROFILE.USER_NAME]: '',
  [INPUTS_CREATE_PROFILE.FULL_NAME]: '',
  [INPUTS_CREATE_PROFILE.DISPLAY_NAME]: '',
  [INPUTS_CREATE_PROFILE.EMAIL]: '',
  [INPUTS_CREATE_PROFILE.BIO]: '',
  [INPUTS_CREATE_PROFILE.SITE]: '',
  [INPUTS_CREATE_PROFILE.FACEBOOK]: '',
  [INPUTS_CREATE_PROFILE.TWITTER]: '',
  [INPUTS_CREATE_PROFILE.INSTAGRAM]: '',
  step: 1,
  img: null,
  id: '',
}
initialState.step = 1

export const profileCreateSlice = createSlice({
  name: 'ProfieCreate',
  initialState,
  reducers: {
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state[name] = value
      return state
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
      return state
    },
    setFetchCreateCollector: (state, action: PayloadAction<any>) => {
      state = { ...action.payload, step: state.step }
      return state
    },
    clearState: (state) => {
      state = initialState
      return state
    },
    addImg: (state, action: PayloadAction<any[] | string>) => {
      state.img = action.payload
    },
  },
})

// Actions
export const { setValueInput, setCurrentStep, addImg, setFetchCreateCollector, clearState } = profileCreateSlice.actions

export default profileCreateSlice.reducer
