import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { NoticeState, NOTICE_VARIANTS } from '../types'

const initialState: NoticeState = {
  open: false,
  title: '',
  variant: NOTICE_VARIANTS.SUCCESS,
}

export const NoticeSlice = createSlice({
  name: 'Notice',
  initialState,
  reducers: {
    setNotice: (
      state,
      action: PayloadAction<{ title: string; open: boolean; variant: NOTICE_VARIANTS; actions?: ReactNode }>,
    ) => {
      state = action.payload
      return state
    },
  },
})

// Actions
export const { setNotice } = NoticeSlice.actions

export default NoticeSlice.reducer
