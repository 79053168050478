import random from 'lodash/random'
import { getBlockchainNetwork, getNetwork } from './network'
import { MAINNET_ETH_CHAIN_ID, TESTNET_ETH_CHAIN_ID } from '../config'

const blockchainNetwork = getBlockchainNetwork(getNetwork())

export const nodes = blockchainNetwork.rpcUrls

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}
export const getSelectedNodeUrl = (chainId: string = TESTNET_ETH_CHAIN_ID) => {
  const selectedBlockchainNetwork = getBlockchainNetwork(chainId)
  const selectedNodes = selectedBlockchainNetwork.rpcUrls
  const randomIndex = random(0, selectedNodes.length - 1)
  return selectedNodes[randomIndex]
}

export default getNodeUrl
