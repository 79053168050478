import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INPUTS_IVITE, INPUTS_PROFILE_EDIT, ProfileEditMuseumState } from '../types'

const initialState: ProfileEditMuseumState = {
  [INPUTS_IVITE.NAME]: '',
  [INPUTS_IVITE.COUNTRY]: '',
  [INPUTS_IVITE.CITY]: '',
  [INPUTS_PROFILE_EDIT.DESCRIPTION]: '',
  [INPUTS_IVITE.SITE]: '',
  [INPUTS_IVITE.FACEBOOK]: '',
  [INPUTS_IVITE.TWITTER]: '',
  [INPUTS_IVITE.INSTAGRAM]: '',
  [INPUTS_PROFILE_EDIT.PINTEREST]: '',
  [INPUTS_PROFILE_EDIT.SPOTIFY]: '',
  [INPUTS_PROFILE_EDIT.YOUTUBE]: '',
  [INPUTS_IVITE.FIRST_NAME]: '',
  [INPUTS_IVITE.LAST_NAME]: '',
  [INPUTS_IVITE.PHONE]: '',
  [INPUTS_IVITE.EMAIL]: '',
  id: '',
  img: null,
  gallery: null,
}
export const profileEditSlice = createSlice({
  name: 'ProfieEdit',
  initialState,
  reducers: {
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state[name] = value
      return state
    },
    setFetchEditMuseumData: (state, action: PayloadAction<any>) => {
      state = action.payload
      return state
    },
    addImg: (state, action: PayloadAction<any[] | string>) => {
      state.img = action.payload
    },
    addGallery: (state, action: PayloadAction<any[] | string>) => {
      state.gallery = action.payload
    },
  },
})

// Actions
export const { setValueInput, setFetchEditMuseumData, addImg, addGallery } = profileEditSlice.actions

export default profileEditSlice.reducer
