import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  INPUTS_REALESE_CREATE,
  ReleaseCreateState,
  ReleaseCreateTabs,
  ReleaseTypeSale,
  RELEASE_CREATE_TYPE,
} from '../types'

const initialState: ReleaseCreateState = {
  inputValues: {
    [INPUTS_REALESE_CREATE.MASTER]: '',
    [INPUTS_REALESE_CREATE.ARTWORK_NAME]: '',
    [INPUTS_REALESE_CREATE.PRICE]: '',
    [INPUTS_REALESE_CREATE.CURRENCY]: '',
    [INPUTS_REALESE_CREATE.FEES]: '',
    [INPUTS_REALESE_CREATE.ABOUT]: '',
    [INPUTS_REALESE_CREATE.DATED]: '',
    [INPUTS_REALESE_CREATE.OBJECT_NAME]: '',
    [INPUTS_REALESE_CREATE.INVENTORY_NUMBER]: '',
    [INPUTS_REALESE_CREATE.MATERIAL]: '',
    [INPUTS_REALESE_CREATE.DMENSIONS]: '',
    [INPUTS_REALESE_CREATE.SIGNED]: '',
    [INPUTS_REALESE_CREATE.PERIOD]: '',
    [INPUTS_REALESE_CREATE.MOVEMENT]: '',
    [INPUTS_REALESE_CREATE.COUNTRY]: '',
    [INPUTS_REALESE_CREATE.SUBJECT_MATTER]: '',
    [INPUTS_REALESE_CREATE.ADDITIONAL]: '',
    [INPUTS_REALESE_CREATE.TECHNIQ]: '',
    [INPUTS_REALESE_CREATE.CATEGORY]: '',
    [INPUTS_REALESE_CREATE.IMAGE]: '',
    [INPUTS_REALESE_CREATE.ARTWORK_IMAGE]: [],
    [INPUTS_REALESE_CREATE.TITLE_PRIMARY]: '',
    [INPUTS_REALESE_CREATE.TITLE_SECONDARY]: '',
    [INPUTS_REALESE_CREATE.ABOUT_ARTWORK]: '',
    [INPUTS_REALESE_CREATE.DATE_END]: '',
    [INPUTS_REALESE_CREATE.DATE_START]: '',
    [INPUTS_REALESE_CREATE.MIN_BET]: '',
    [INPUTS_REALESE_CREATE.PRICE_USD]: '',
    docs: [],
    ifps: [],
  },
  acceptTerm: false,
  saleType: ReleaseTypeSale.FIX_PRICE,
  type: RELEASE_CREATE_TYPE.NONE,
  step: 1,
  id: '',
  tabValue: ReleaseCreateTabs.PROMO,
}

export const releaseCreateSlice = createSlice({
  name: 'ReleaseCreate',
  initialState,
  reducers: {
    setTabValue: (state, action: PayloadAction<ReleaseCreateTabs>) => {
      state = { ...state, tabValue: action.payload }
      return state
    },
    setTypeReleaseCreate: (state, action: PayloadAction<RELEASE_CREATE_TYPE>) => {
      state.type = action.payload
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
      return state
    },
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state.inputValues[name] = value
      return state
    },
    addImg: (state, action: PayloadAction<string>) => {
      state.inputValues.img = action.payload
    },
    addArtworkImg: (state, action: PayloadAction<any>) => {
      state.inputValues.artworkImg = action.payload
    },
    addDocs: (state, action: PayloadAction<any>) => {
      state.inputValues.docs = action.payload
    },
    addIfps: (state, action: PayloadAction<any>) => {
      state.inputValues.ifps = action.payload
    },
    addPriceUsd: (state, action: PayloadAction<any>) => {
      state.inputValues.priceUsd = action.payload
    },
    setIdToken: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setAcceptTerm: (state, action: PayloadAction<boolean>) => {
      state.acceptTerm = action.payload
    },
    setSaleType: (state, action: PayloadAction<ReleaseTypeSale>) => {
      state.saleType = action.payload
    },
    clearState: (store) => {
      store = initialState
      return store
    },
  },
})

// Actions
export const {
  setTypeReleaseCreate,
  setCurrentStep,
  setValueInput,
  setTabValue,
  addImg,
  setIdToken,
  clearState,
  addDocs,
  addIfps,
  addArtworkImg,
  setAcceptTerm,
  setSaleType,
  addPriceUsd,
} = releaseCreateSlice.actions

export default releaseCreateSlice.reducer
