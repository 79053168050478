import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { OFAM_ID } from 'routes'
import { ReleaseState } from '../types'

const initialState: ReleaseState = {
  openInfo: false,
  releaseList: [],
  isFetching: true,
  release: {},
}

export const fetchReleaseListData = createAsyncThunk<{ data: any }, { limit: number }>(
  'Release/list',
  async ({ limit }) => {
    const response = await axios(
      `${process.env.REACT_APP_API}/release/explore?limit=${limit}&offset=0&orderBy=createdAt&orderSort=DESC&museumId=${OFAM_ID}`,
    )
    if (response?.status === 200) {
      const data = response.data.orders
      return { data }
    }
    return {
      data: [],
    }
  },
)

export const fetchReleasData = createAsyncThunk<{ data: any }, { id: string }>('Release/page', async ({ id }) => {
  const response = await axios(`${process.env.REACT_APP_API}/release/${id}`)
  if (response?.status === 200) {
    const { data } = response
    return { data }
  }
  return {
    data: {},
  }
})

export const releaseSlice = createSlice({
  name: 'Market',
  initialState,
  reducers: {
    setInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.openInfo = action.payload
      return state
    },
    setReleaseState: (state, action: PayloadAction<{ release: any; isFetching: boolean }>) => {
      const { isFetching, release } = action.payload
      state = { ...state, isFetching, release }
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReleaseListData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.releaseList = data
      state.isFetching = state.isFetching && false
    })
    builder.addCase(fetchReleasData.fulfilled, (state, action) => {
      const { data } = action.payload
      state.release = data
      state.isFetching = state.isFetching && false
    })
  },
})

// Actions
export const { setInfoOpen, setReleaseState } = releaseSlice.actions

export default releaseSlice.reducer
