import React, { lazy } from 'react'
import BigNumber from 'bignumber.js'
import ROUTES from 'routes'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import NoticeGlobal from 'components/Notice/NoticeVariants'
import useEagerConnect from 'hooks/useEagerConnect'
import ScrollToTop from 'components/ScrollToTop'
import ResetCSS from 'styles/ResetCSS'
import { ContentHeight, ToastWrapper } from 'styles/common/styles'
import ToastListener from 'components/Toast/ToastListener'
import Suspense from 'components/Suspense'
import Cookies from 'components/Cookies'
import SuspenseWithChunkError from './components/SuspenseWithChunkError/SuspenseWithChunkError'
import GlobalStyle from './styles/Global'
import './styles/App.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const HomePage = lazy(() => import('./projects/artwhale/pages/Home'))
const ReleasesPage = lazy(() => import('./projects/artwhale/pages/Releases'))
const MuseumsListPage = lazy(() => import('./projects/artwhale/pages/Museums/List'))
const Museum = lazy(() => import('./projects/artwhale/pages/Museums/Museum'))
const Market = lazy(() => import('./projects/artwhale/pages/Market'))
const Realease = lazy(() => import('./projects/artwhale/pages/Release'))
const SignIn = lazy(() => import('./projects/artwhale/pages/SignIn'))
const Artwork = lazy(() => import('./projects/artwhale/pages/Artwork'))
const Profile = lazy(() => import('./projects/artwhale/pages/Profile'))
const ProfileCreate = lazy(() => import('./projects/artwhale/pages/Profile/Create'))
const Invitation = lazy(() => import('./projects/artwhale/pages/Invitation'))
const EditProfile = lazy(() => import('./projects/artwhale/pages/Profile/Edit'))
const ReleaseCreate = lazy(() => import('./projects/artwhale/pages/Museums/Museum/ReleaseCreate'))
const EditProfileMuseum = lazy(() => import('./projects/artwhale/pages/Museums/Museum/Edit'))
const ProfileSettings = lazy(() => import('./projects/artwhale/pages/Museums/Museum/ProfileSettings'))
const SearchPage = lazy(() => import('./projects/artwhale/pages/Search'))
const FriendsCreate = lazy(() => import('./projects/artwhale/pages/Museums/Museum/FriendsCreate'))
const FriendMuseum = lazy(() => import('./projects/artwhale/pages/FriendMuseum'))
const Landing = lazy(() => import('./projects/ofam'))
const KYCPage = lazy(() => import('./projects/artwhale/pages/Profile/KYCPage'))
const FaqPage = lazy(() => import('./projects/artwhale/pages/Faq'))
const PrivacyPage = lazy(() => import('./projects/artwhale/pages/Pricavy'))
const TermsPage = lazy(() => import('./projects/artwhale/pages/Terms'))
const HelpPage = lazy(() => import('./projects/artwhale/pages/Help'))
const NotFound = lazy(() => import('./projects/artwhale/pages/NotFound'))
const Cookie = lazy(() => import('./projects/artwhale/pages/Cookie'))
const CookiePolicy = lazy(() => import('./projects/artwhale/pages/CookiePolicy'))

const App: React.FC = () => {
  useEagerConnect()
  return (
    <BrowserRouter>
      <ResetCSS />
      <GlobalStyle />
      <NoticeGlobal />
      {/*  @ts-ignore */}
      <SuspenseWithChunkError fallback={<Suspense />}>
        <ToastWrapper>
          <ToastListener />
        </ToastWrapper>
        <ScrollToTop />
        <ContentHeight>
          <Switch>
            <Route path={ROUTES.FRIENDS_CREATE} exact component={FriendsCreate} />
            <Route path={ROUTES.SIGNIN_URL} exact component={SignIn} />
            {process.env.REACT_APP_PROJECT === 'OFAM' ? (
              <Route path={ROUTES.HOME_URL} exact component={Landing} />
            ) : (
              <Route path={ROUTES.HOME_URL} exact component={HomePage} />
            )}
            <Route path={ROUTES.RELEASES_URL} exact component={ReleasesPage} />
            {process.env.REACT_APP_PROJECT === 'ARTWHALE' && (
              <Route path={ROUTES.MUSEUMS_LIST_URL} exact component={MuseumsListPage} />
            )}
            <Route path={ROUTES.MUSEUM_RELEASE_URL} component={Museum} />
            {process.env.REACT_APP_PROJECT === 'ARTWHALE' && (
              <Route path={ROUTES.MARKET_URL} exact component={Market} />
            )}
            <Route path={`${ROUTES.RELEASE_URL}/:id`} component={Realease} />
            <Route path={`${ROUTES.ARTWORK_URL}/:id`} component={Artwork} />
            <Route path={`${ROUTES.PROFILE_URL}/:id`} exact component={Profile} />
            <Route path={ROUTES.PROFILE_CREATE_URL} exact component={ProfileCreate} />
            <Route path={ROUTES.INVITATION_URL} exact component={Invitation} />
            <Route path={ROUTES.PROFILE_EDIT_URL} exact component={EditProfile} />
            <Route path={ROUTES.RELEASE_CREATE_URL} exact component={ReleaseCreate} />
            <Route path={ROUTES.PROFILE_SETTINGS_URL} exact component={ProfileSettings} />
            <Route path={ROUTES.PROFILE_SETTINGS_MUSEUM_URL} exact component={EditProfileMuseum} />
            <Route path={ROUTES.SEARCH_PAGE} exact component={SearchPage} />
            <Route path={`${ROUTES.FRIENDS_MUSEUM}/:id`} exact component={FriendMuseum} />
            <Route
              path={`${ROUTES.FRIENDS_MUSEUM}/:id${ROUTES.COLLECTOR_URL}/:idCollector`}
              exact
              component={FriendMuseum}
            />
            <Route path={ROUTES.KYC_URL} exact component={KYCPage} />
            <Route path={`${ROUTES.FRIENDS_EDIT}/:id`} exact component={FriendsCreate} />
            <Route path={ROUTES.FAQ_PAGE} exact component={FaqPage} />
            <Route path={ROUTES.PRIVACY_PAGE} exact component={PrivacyPage} />
            <Route path={ROUTES.TERMS_PAGE} exact component={TermsPage} />
            <Route path={ROUTES.HELP_PAGE} exact component={HelpPage} />
            <Route path={ROUTES.COOKIE_PAGE} exact component={Cookie} />
            <Route path={ROUTES.COOKIES_POLICY_PAGE} exact component={CookiePolicy} />
            <Route path="*" component={NotFound} />
          </Switch>
        </ContentHeight>
        <Cookies />
      </SuspenseWithChunkError>
    </BrowserRouter>
  )
}

export default React.memo(App)
