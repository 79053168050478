// Set of helper functions to facilitate wallet setup
import { getBlockchainNetwork, getNetwork } from './network'
import web3NoAccount from './web3'

interface WindowChain {
  // @TODO
  // walletLinkExtension is injected by the Coinbase Wallet extension
  walletLinkExtension?: any
  ethereum?: {
    // value that is populated and returns true by the Coinbase Wallet mobile dapp browser
    isCoinbaseWallet?: true
    isMetaMask?: true
    request?: (...args: any[]) => void
  }
}

/**
 * Prompt the user to add BSC/AVAX as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = (window as WindowChain).ethereum
  const blockchainNetwork = getBlockchainNetwork(getNetwork())
  if (provider) {
    const chainId = parseInt(blockchainNetwork.chainId, 10)
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3NoAccount.utils.toHex('1') }],
        // method: 'wallet_switchEthereumChain',
        // params: [
        //   {
        //     chainId: `0x${chainId.toString(16)}`,
        //     chainName: blockchainNetwork.chainName,
        //     nativeCurrency: {
        //       name: blockchainNetwork.nativeCurrency.name,
        //       symbol: blockchainNetwork.nativeCurrency.symbol,
        //       decimals: blockchainNetwork.nativeCurrency.decimals,
        //     },
        //     rpcUrls: blockchainNetwork.rpcUrls,
        //     blockExplorerUrls: blockchainNetwork.blockExplorerUrls,
        //   },
        // ],
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string,
) => {
  const tokenAdded = await (window as WindowChain).ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  })

  return tokenAdded
}
