import {
  MAINNET_AVAX_CHAIN_ID,
  MAINNET_ETH_CHAIN_ID,
  NETWORK_CHAIN,
  NETWORK_TYPE,
  TESTNET_AVAX_CHAIN_ID,
  TESTNET_ETH_CHAIN_ID,
  TESTNET_CHAIN_ID,
} from '../config'
import { NETWORK_AVAX, NETWORK_BSC } from '../config/constants/endpoints'

export const getDefaultNetwork = (): string => MAINNET_ETH_CHAIN_ID
export const getNetwork = (): string => {
  try {
    const networkFromLs = localStorage.getItem('blockchainNetwork')
    return getDefaultNetwork()
    // networkFromLs ||
  } catch (error) {
    return getDefaultNetwork()
  }
}

export const prepareSetNetwork = (chainId: string) => {
  let blockchainNetworkSettings = NETWORK_CHAIN.find((network) => {
    return network.chainId === chainId
  })
  if (!blockchainNetworkSettings) {
    blockchainNetworkSettings = NETWORK_CHAIN.find((network) => {
      return network.chainId === MAINNET_ETH_CHAIN_ID
    })
  }
  return blockchainNetworkSettings.chainId
}

export const getNetworkbyChainId = (chainId: string) => {
  switch (chainId) {
    case '1' || '43114':
      return NETWORK_TYPE.MAINNET

    case '1' || '43113':
      return NETWORK_TYPE.TESTNET

    default:
      return NETWORK_TYPE.MAINNET
  }
}

/**
 * @Todo
 * Get сhainId by abbreviation netowrk
 * @param name
 * @param type
 */
export const getChainIdByAbbreviation = (name: string, type?: string) => {
  switch (name) {
    case NETWORK_BSC:
      if (type === NETWORK_TYPE.MAINNET) {
        return MAINNET_ETH_CHAIN_ID
      }
      return TESTNET_ETH_CHAIN_ID
    case NETWORK_AVAX:
      if (type === NETWORK_TYPE.MAINNET) {
        return MAINNET_ETH_CHAIN_ID
      }
      return TESTNET_AVAX_CHAIN_ID

    default:
      return MAINNET_ETH_CHAIN_ID
  }
}

export const setNetwork = (chainId: string): void => {
  localStorage.setItem('blockchainNetwork', chainId)
}

export const getBlockchainNetwork = (chainId: string) => {
  let blockchainNetworkSettings = NETWORK_CHAIN.find((network) => {
    return network.chainId === chainId
  })

  if (!blockchainNetworkSettings) {
    blockchainNetworkSettings = NETWORK_CHAIN.find((network) => {
      return network.chainId === MAINNET_ETH_CHAIN_ID
    })
  }
  return blockchainNetworkSettings
}

export const getBlockchainNetworkNameCurrency = () => {
  const blockchainNetwork = getBlockchainNetwork(getNetwork())
  return blockchainNetwork.nativeCurrency.name
}

export const getBridgeNetwork = (chainId: string) => {
  switch (chainId) {
    case MAINNET_AVAX_CHAIN_ID:
    case TESTNET_AVAX_CHAIN_ID:
      return MAINNET_ETH_CHAIN_ID
    case TESTNET_CHAIN_ID:
    case TESTNET_ETH_CHAIN_ID:
    default:
      return MAINNET_ETH_CHAIN_ID
  }
}
