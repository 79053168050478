import Button from 'components/Button'
import Container from 'components/Layout/Container'
import { COOKIES_ANALITYC, COOKIES_ITEM, COOKIES_META } from 'config/constants/ls'
import COLOR_WHITE, { COLOR_GRAY_2, COLOR_SHADOW_3 } from 'constants/styles/colors'
import mediaQueries from 'constants/styles/mediaQueries'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ROUTES from 'routes'
import styled from 'styled-components'

export default function Cookies() {
  const [show, setShow] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (history?.location?.pathname !== ROUTES.COOKIE_PAGE) {
      setTimeout(() => {
        const ls = localStorage.getItem(COOKIES_ITEM)
        if (ls) setShow(false)
        else setShow(true)
      }, 3000)
    }
  }, [history?.location?.pathname])

  const handleCoockies = () => {
    localStorage.setItem(COOKIES_ITEM, 'yes')
    localStorage.setItem(COOKIES_META, 'yes')
    localStorage.setItem(COOKIES_ANALITYC, 'yes')
    setShow(false)
  }

  const handleAccept = () => {
    history.push(ROUTES.COOKIE_PAGE)
    setShow(false)
  }

  return (
    <Wrapper open={show}>
      <Container>
        <Inner>
          <div>
            <Title>Cookies for the best experience</Title>
            <Text>
              Our website uses cookies. Would you rather not accept cookies, or would like to{' '}
              <LinkStyle to={ROUTES.COOKIES_POLICY_PAGE}>read more</LinkStyle> about the cookies we use? If no, select
              `Cancel Cookies` {/* If so, select  ‘Manage Cookies’ */}
            </Text>
          </div>
          <Buttons>
            <Button onClick={handleAccept} variant="white">
              Manage Cookies
            </Button>
            <Button onClick={handleCoockies}>Accept</Button>
          </Buttons>
        </Inner>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ open: boolean }>`
  background: ${COLOR_WHITE};
  position: fixed;
  bottom: ${({ open }) => (open ? 0 : '-100%')};
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 999;
  box-shadow: ${COLOR_SHADOW_3};
  transition: 0.4s;
`
const Inner = styled.div`
  padding: 28px 0;
  ${mediaQueries.md} {
    display: flex;
    gap: 40px;
    align-items: center;
  }
`
const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;
  color: ${COLOR_GRAY_2};
  ${mediaQueries.md} {
    font-size: 25px;
    line-height: 160%;
  }
`
const Text = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: ${COLOR_GRAY_2};
  ${mediaQueries.md} {
    margin-top: 14px;
    font-size: 18px;
    line-height: 140%;
  }
`
const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  ${mediaQueries.lg} {
    & button {
      width: 320px;
    }
  }
`
const LinkStyle = styled(Link)`
  text-decoration: underline;
`
