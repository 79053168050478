import COLOR_WHITE, { COLOR_GRAY_2 } from 'constants/styles/colors'
import React from 'react'
import styled, { keyframes } from 'styled-components'

export default function Loader({ size }: { size?: 'medium' }) {
  return <LoaderStyle size={size} />
}
const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
  `

const LoaderStyle = styled.div<{ size?: 'medium' }>`
  --size: ${({ size }) => (size === 'medium' ? '24px' : '80px')};
  --stroke-size: ${({ size }) => (size === 'medium' ? '2px' : '4px')};
  --diff: calc(calc(var(--size) / 2) - var(--stroke-size));
  background-image: conic-gradient(transparent 0, ${({ size }) => (size === 'medium' ? COLOR_GRAY_2 : COLOR_WHITE)});
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  -webkit-mask: radial-gradient(circle var(--diff), transparent 98%, ${COLOR_WHITE} 100%);
  mask: radial-gradient(circle var(--diff), transparent 98%, ${COLOR_WHITE} 100%);
  animation: rotate 1.2s linear infinite;
  margin: 0 auto;
  animation: ${spin} 2s linear infinite;
  position: relative;
  z-index: 11;
`
