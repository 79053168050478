import mediaQueries from 'constants/styles/mediaQueries'
import paddingContainer from 'constants/styles/values'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 1620px;
  width: 100%;
  margin: 0 auto;
  padding: ${paddingContainer.xs};
  box-sizing: border-box;
  ${mediaQueries.xmd} {
    padding: ${paddingContainer.xmd};
  }
  ${mediaQueries.lg} {
    padding: ${paddingContainer.lg};
  }
  ${mediaQueries.xl} {
    padding: ${paddingContainer.xl};
  }
  ${mediaQueries.xxl} {
    padding: ${paddingContainer.xxl};
  }
`

export default Container
