import React from 'react'

export default function InfoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.416504 12.5013C0.416504 5.8313 5.82984 0.417969 12.4998 0.417969C19.1698 0.417969 24.5832 5.8313 24.5832 12.5013C24.5832 19.1713 19.1698 24.5846 12.4998 24.5846C5.82984 24.5846 0.416504 19.1713 0.416504 12.5013ZM13.7082 11.293V18.543H11.2915V11.293H13.7082ZM12.4998 22.168C7.17108 22.168 2.83316 17.83 2.83316 12.5013C2.83316 7.17255 7.17108 2.83463 12.4998 2.83463C17.8286 2.83463 22.1665 7.17255 22.1665 12.5013C22.1665 17.83 17.8286 22.168 12.4998 22.168ZM13.7082 6.45963V8.8763H11.2915V6.45963H13.7082Z"
        fill="#BCBCBC"
      />
    </svg>
  )
}
