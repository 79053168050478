import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProfileState, ProfileTabs } from '../types'

const initialState: ProfileState = {
  tabValue: ProfileTabs.COLLECTIONS,
}

export const profileSlice = createSlice({
  name: 'Profile',
  initialState,
  reducers: {
    setTabValue: (state, action: PayloadAction<ProfileTabs>) => {
      state = { ...state, tabValue: action.payload }
      return state
    },
  },
})

// Actions
export const { setTabValue } = profileSlice.actions

export default profileSlice.reducer
