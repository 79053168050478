import { useEffect, useMemo } from 'react'
// @todo
import { connectorLocalStorageKey, ConnectorNames } from 'config'
import useAuth from 'hooks/useAuth'
import { getToken } from 'utils/session'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

const _binanceChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc
      },
      set(bsc) {
        this.bsc = bsc

        resolve()
      },
    }),
  )

const useEagerConnect = () => {
  const { loginWithoutNetwork } = useAuth()
  const data = useMemo(() => getToken(), [])
  const { error } = useWeb3React()

  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames
    if (connectorId && data?.token) {
      const isConnectorBinanceChain = connectorId === ConnectorNames.BSC
      const isBinanceChainDefined = Reflect.has(window, 'BinanceChain')

      // Currently BSC extension doesn't always inject in time.
      // We must check to see if it exists, and if not, wait for it before proceeding.
      if (isConnectorBinanceChain && !isBinanceChainDefined) {
        _binanceChainListener().then(() => loginWithoutNetwork(connectorId))

        return
      }

      // if (!isWrongNetwork) {
      loginWithoutNetwork(connectorId)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isWrongNetwork])
}

export default useEagerConnect
