import React from 'react'

export default function CheckIcon() {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 10.3667L0 5.55901L1.4 4.21285L5 7.67439L12.6 0.366699L14 1.71285L5 10.3667Z"
        fill="#EEEEEE"
      />
    </svg>
  )
}
