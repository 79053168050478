import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FriendsCreateTabs, INPUTS_CREATE_MUSEUM_FRIENDS, MusemFriendsState } from '../types'

const initialState: MusemFriendsState = {
  tabValue: FriendsCreateTabs.DESCRIPTION,
  inputValues: {
    [INPUTS_CREATE_MUSEUM_FRIENDS.PRIMARY_TITLE]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.SECONDARY_TITLE]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.ABOUT]: '',
    img: null,
    [INPUTS_CREATE_MUSEUM_FRIENDS.BENEFITS_CONDITION]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.BENEFITS_SERVICE]: [],
    [INPUTS_CREATE_MUSEUM_FRIENDS.REWARDS_ABOUT]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.REWARDS_LIST]: [],
    [INPUTS_CREATE_MUSEUM_FRIENDS.ACTIVITIES_LIST]: [],

    [INPUTS_CREATE_MUSEUM_FRIENDS.CIRCULATION]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.PRICE]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.PERPETUAL_ROYALTY]: '',
    [INPUTS_CREATE_MUSEUM_FRIENDS.CHECKING_AGREE]: false,
  },
  benefitsTabSaved: false,
  rewardTabSaved: false,
  services: {},
  stateChanged: false,
}

export const museumFriendsSlice = createSlice({
  name: 'MuseumFriends',
  initialState,
  reducers: {
    setTabValue: (state, action: PayloadAction<FriendsCreateTabs>) => {
      state = { ...state, tabValue: action.payload }
      return state
    },
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state.inputValues[name] = value
      return state
    },
    addImg: (state, action: PayloadAction<any[] | string>) => {
      state.inputValues.img = action.payload
    },
    addService: (state, action: PayloadAction<any>) => {
      state.inputValues.benefitsService = state.inputValues.benefitsService.length
        ? [...state.inputValues.benefitsService, action.payload]
        : [action.payload]
    },
    deleteService: (state, action: PayloadAction<any>) => {
      state.inputValues.benefitsService = state.inputValues.benefitsService.filter((el) => el.id !== action.payload)
    },
    editService: (state, action: PayloadAction<any>) => {
      state.inputValues.benefitsService = state.inputValues.benefitsService.map((el) =>
        el.id !== action.payload.id ? el : { ...el, ...action.payload },
      )
    },
    addRewards: (state, action: PayloadAction<any>) => {
      state.inputValues.rewards = state.inputValues.rewards.length
        ? [...state.inputValues.rewards, action.payload]
        : [action.payload]
    },
    deleteRewards: (state, action: PayloadAction<any>) => {
      state.inputValues.rewards = state.inputValues.rewards.filter((el) => el.id !== action.payload)
    },
    editRewards: (state, action: PayloadAction<any>) => {
      state.inputValues.rewards = state.inputValues.rewards.map((el) =>
        el.id !== action.payload.id ? el : { ...el, ...action.payload },
      )
    },
    addActivities: (state, action: PayloadAction<any>) => {
      state.inputValues.activitiesList = state.inputValues.activitiesList.length
        ? [...state.inputValues.activitiesList, action.payload]
        : [action.payload]
    },
    deleteActivities: (state, action: PayloadAction<any>) => {
      state.inputValues.activitiesList = state.inputValues.activitiesList.filter((el) => el.id !== action.payload)
    },
    editActivities: (state, action: PayloadAction<any>) => {
      state.inputValues.activitiesList = state.inputValues.activitiesList.map((el) =>
        el.id !== action.payload.id ? el : { ...el, ...action.payload },
      )
    },
    clearState: (store) => {
      store = initialState
      return store
    },
    setBenefitsTabSaved: (state, action: PayloadAction<boolean>) => {
      state.benefitsTabSaved = action.payload
    },
    setRewardTabSaved: (state, action: PayloadAction<boolean>) => {
      state.rewardTabSaved = action.payload
    },
    setServices: (state, action: PayloadAction<any>) => {
      state.services = action.payload
    },
    setArtworkValues: (state, action: PayloadAction<any>) => {
      state.inputValues = action.payload
    },
    setStateChanged: (state, action: PayloadAction<boolean>) => {
      state.stateChanged = action.payload
    },
  },
})

// Actions
export const {
  setTabValue,
  setValueInput,
  addImg,
  addService,
  deleteService,
  editService,
  addRewards,
  deleteRewards,
  editRewards,
  addActivities,
  deleteActivities,
  editActivities,
  clearState,
  setBenefitsTabSaved,
  setRewardTabSaved,
  setServices,
  setArtworkValues,
  setStateChanged,
} = museumFriendsSlice.actions

export default museumFriendsSlice.reducer
