import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INPUTS_IVITE, INPUTS_PROFILE_EDIT, InviteState } from '../types'

const initialState: InviteState = {
  [INPUTS_IVITE.FIRST_NAME]: '',
  [INPUTS_IVITE.LAST_NAME]: '',
  [INPUTS_IVITE.PHONE]: '',
  [INPUTS_IVITE.EMAIL]: '',
  [INPUTS_IVITE.SITE]: '',
  [INPUTS_IVITE.NAME]: '',
  [INPUTS_IVITE.COUNTRY]: '',
  [INPUTS_IVITE.CITY]: '',
  [INPUTS_PROFILE_EDIT.DESCRIPTION]: '',
  [INPUTS_IVITE.SITE]: '',
  [INPUTS_IVITE.FACEBOOK]: '',
  [INPUTS_IVITE.TWITTER]: '',
  [INPUTS_IVITE.INSTAGRAM]: '',
  [INPUTS_PROFILE_EDIT.PINTEREST]: '',
  [INPUTS_PROFILE_EDIT.SPOTIFY]: '',
  [INPUTS_PROFILE_EDIT.YOUTUBE]: '',
  step: 1,
}
initialState.step = 1

export const inviteSlice = createSlice({
  name: 'Invate',
  initialState,
  reducers: {
    setValueInput: (state, action: PayloadAction<{ name: string; value: string }>) => {
      const { name, value } = action.payload
      state[name] = value
      return state
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
      return state
    },
  },
})

// Actions
export const { setValueInput, setCurrentStep } = inviteSlice.actions

export default inviteSlice.reducer
