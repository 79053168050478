import { MediaQueries } from './types'

export const breakpointMap: { [key: string]: number } = {
  xs: 375,
  sm: 576,
  xmd: 767,
  md: 852,
  lg: 1025,
  xl: 1281,
  xl1440: 1441,
  xxl: 1681,
  xxxl: 1921,
}

const mediaQueries: MediaQueries = {
  xs: `@media screen and (min-width: ${breakpointMap.xs}px)`,
  sm: `@media screen and (min-width: ${breakpointMap.sm}px)`,
  xmd: `@media screen and (min-width: ${breakpointMap.xmd}px)`,
  md: `@media screen and (min-width: ${breakpointMap.md}px)`,
  lg: `@media screen and (min-width: ${breakpointMap.lg}px)`,
  xl: `@media screen and (min-width: ${breakpointMap.xl}px)`,
  xl1440: `@media screen and (min-width: ${breakpointMap.xl1440}px)`,
  xxl: `@media screen and (min-width: ${breakpointMap.xxl}px)`,
  xxxl: `@media screen and (min-width: ${breakpointMap.xxxl}px)`,
  nav: `@media screen and (min-width: ${breakpointMap.lg}px)`,
}

export default mediaQueries
