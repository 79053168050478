import React from 'react'
import { COLOR_GRAY_RGBA } from 'constants/styles/colors'
import styled from 'styled-components'

export default function Overlay({ open }: { open: boolean }) {
  return <OverlayBlock open={open} />
}

const OverlayBlock = styled.div<{ open: boolean; zIndex?: number }>`
  background: ${COLOR_GRAY_RGBA};
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transition: all 0.3s;
`
