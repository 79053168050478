import COLOR_WHITE, {
  COLOR_BLACK_TEXT,
  COLOR_GRAY,
  COLOR_GRAY_2,
  COLOR_GRAY_3,
  COLOR_GRAY_4,
} from 'constants/styles/colors'
import mediaQueries from 'constants/styles/mediaQueries'
import styled, { keyframes } from 'styled-components'

const skeleton = keyframes`
  0% { background: ${COLOR_GRAY_4}};
  25% { background: ${COLOR_GRAY_4}};
  50% { background: ${COLOR_GRAY_3}};
  75% { background: ${COLOR_GRAY_4}};
  100% { background: ${COLOR_GRAY_4}};
`

export const ContentCreate = styled.div`
  max-width: 620px;
  margin: 0 auto 50px;
  min-height: calc(100vh - 290px);
`
export const DescriptionCreate = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: ${COLOR_GRAY_2};
`
export const StepsWrapperCreater = styled.div`
  padding: 40px 0;
  border-bottom: 2px solid ${COLOR_BLACK_TEXT};
`
export const NoticeTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: ${COLOR_BLACK_TEXT};
`
export const NoticeText = styled.div`
  margin-right: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${COLOR_BLACK_TEXT};
`
export const NoticeButtonWrapper = styled.div`
  margin: 10px 0 0 auto;
  display: flex;
  justify-content: flex-end;
  width: 223px;
  flex: none;
  padding-left: 20px;
`
export const NoticeInner = styled.div``
export const NoticeWrapper = styled.div`
  margin-bottom: 60px;
`
export const ToastWrapper = styled.div`
  order: -1;
  width: 100%;
  max-width: 320px;
  margin: 0 auto 20px;
  top: 100px;
  right: 20px;
  position: fixed !important;
  z-index: 220;
`
export const TitleText = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 34px;
  line-height: 120%;
  color: ${COLOR_BLACK_TEXT};
  ${mediaQueries.xmd} {
    padding-bottom: 24px;
  }
`
export const ContentHeight = styled.div`
  min-height: calc(100vh - 370px);
  ${mediaQueries.lg} {
    min-height: calc(100vh - 490px);
  }
`
export const SkeletonBlock = styled.div<{
  height?: string
  width?: string
  margin?: string
  gridArea?: string
  maxWidth?: string
}>`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  animation: ${skeleton} 2s ease-in-out infinite;
  ${({ gridArea }) =>
    gridArea &&
    `
    grid-area: ${gridArea};
  `};
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
  `};
`
export const SkeletonRound = styled.div<{ size?: string; margin?: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin: ${({ margin }) => margin};
  border-radius: 50%;
  animation: ${skeleton} 2s ease-in-out infinite;
`
export const Empty = styled.div`
  padding-bottom: 170px;
  font-weight: 600;
  font-size: 29px;
  line-height: 140%;
  color: ${COLOR_GRAY};
`

export const Date = styled.div`
  background: ${COLOR_BLACK_TEXT};
  box-sizing: border-box;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${COLOR_WHITE};
`
